export const environment = {
  production: true,
  linkdInUr_AccessToken:'https://www.linkedin.com/oauth/v2/',
  linkdInApi:'https://api.linkedin.com/v2/',
  firebase: {
  },
  debug: false,
  log: {
    auth: false,
    store: false,
  },

  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  // API_END_POINT_ADMIN : 'https://fkbwl.api.io-market.com/api/v1/',
  API_END_POINT_BUSINESS : 'https://api-business.io-procurement.com/api/v1/',
  // API_END_POINT_ACCOUNT : 'https://fjy29.api.io-market.com/api/v1/',
  // LOGIN_URL:"https://accounts.io-market.com/#/auth/login/",
  // ADMIN_URL:"https://admin.io-market.com/#/",
  LOGIN_URL: {'io-market.com':'https://accounts.io-market.com/#/auth/login/',
    'epost.gate2b.com':'https://accounts.epost.gate2b.com/#/auth/login/',
  'xatena.io-market.com':'https://accounts.xatena.io-market.com/#/auth/login/'},
  ADMIN_URL:{'xatena.io-market.com':'https://admin.xatena.io-market.com/#/',
    'io-market.com':'https://admin.io-market.com/',
    'epost.gate2b.com':'https://admin.epost.gate2b.com/#/'},
  allowedRedirectPath:['business.io-market.com','contract.comgate.io-market.com','gate2b.io-market.com','fkbwl.api.io-market.com','dhdgu.api.io-market.com','fjy29.api.io-market.com'],
  // profile_URL :"https://accounts.io-market.com/#/profile",
  profile_URL:{'io-market.com':'https://accounts.io-market.com/#/profile',
    'epost.gate2b.com':'https://accounts.epost.gate2b.com/#/profile',
    'xatena.io-market.com':'https://accounts.xatena.io-market.com/#/profile'},
  privateKey:{key:"MIIEowIBAAKCAQEAzheqwMQrSFIFkQtsiPFKUDHNSqlae2Hfkfr/7J3cF0hrSfcBHcT/63c3gGt02J0dZE+WWcJ0qt4jNZflxUnzuYpPJw+tv+9Kenw/ghHNKtfiaB20SPl6naOKusPrTbWTjC7MnL3jbs9wBtZxC14vufQ5E1TyHKKxuQtW93ej9OclK/e3aoCv68yhQbHEzS2W8mcp8YfzeyF+BkoMGfPYUgeuV+XLSPrgoK6PBPZlAZe7gSlCwo+bbwg3ShafZcRUMg2ParSP3y1DMaoyxmRERWEChyzbBSYzARrzoV75I4zkzySbnY16VV7Rxtmxv08EGJM89auNx09lpO+fJr30WQIDAQABAoIBAF3ZgTSPIHaqg0ZtwVsLJiAmzam2PTH6e+jqOCj0KgmgPNtN1ABOeYwsmeWtCWSrB3tqkrkFSEOXLMl3Bnc1Gfvf8/OBvEmvGmd0tSbyHE/qR3vKKxMgKKook0BBf6G/khVAKoUmI6+OXyF9/bSDi5BOmr8m66oGnI/7jfrbTJp05qO5VQZDVoc5DxFqDgDNr8pqCZhMaYWXD02Oxs+iiHoiFWGth+wjQVvfmd15Yqvwpb09TMB3YVkQ+cnfI9i07SZZ0/o2b8iEFBQTbhNfHDm8QMRJ5k6lcjbREFIWuh0jI6ti9PFFVYnyyTuRioM15rmmGb9iktmW5V9As2WeZgECgYEA72rLgHNHnIjbI5WzioeztRPpyLM09McjuWrxYOh3UFLJvs2mR8KNJ+7ifhqkZRhLP0T/jnNSiWN67mjKANGClJX1ODnZalXAoz4fGH5tUR7VClCFuRUI3bSYSFSkMwmoBipKWykkrf5TcgO9KO43T0gpRs/VihlbUO2EMdH2NLECgYEA3F36SOFCpFTp0doHolB+KopgP8T7Cc2MbbliuAoejOXdE+mcG4MJ7hdRP9g1y0zPBvD1tHdHfFbP2/wFKupfaYca5N0dWefRL/2inz/bXdpWFCN76Wv7bQGLocSxD9aZEXAgjcHU2ys5vHyTtzMm7JMXezIvevIdrPk/AUN4xCkCgYAJ78TCcAZo2MU/lNb+yOMMgOcTDV0FS76P04BxJTTOgKEe6iockFaLcb60vSZVi8yTs6lnoCGs0hQoEPFRV2C0CgVDcCM15pk4LTvdKGIhG8Z7xxvFsrnLedNYLSsjcyMmHls3rV14WzXbZYxURo7FG5ma9sVGFgdUNIdoCe7OAQKBgHjXSAJjFEpNDhM3fmwxu53UV8dgW1Ea2GDrAglt8ybMfhkHR8WGbBGV08Yw5/7d1cE2rWYS6q5yZHV7k9HeyAd0QPkzj37Xskygx2aQe9jRe9Z7Rt3PiwZRjKJe28GVqkZ3uEJEAldVMsn7fn09naCpYHz8HjFLR56s5zHoL/dRAoGBAIlchmCpCCZeWNxZ+EbGtqJHPCC7tm2symaMydadmNNYvWKE1AznO6if7LrdrmZU0tqJG0ut2bYAinsLei/gUQyOWSUgm6bMQ4lpsBfkCNAxztDviOOwi3Av5aTs6Ic00eMWUHWSwQJIMrPmzQBxzxy9itB6x14UdAw5ivJfkf2D"},
  encryptionKey : "it6Hig8a56YacWPPuduLbA==",
  cookieDomain:".io-market.com",
  checkCookie:"expireCookie",
  tokenExpireTime:"cookieCreatedTime",

   //LinkdIn
   client_id:"787c2dgbaj6gvq",
  scope:"r_liteprofile%20r_emailaddress",
  redirect_uri:"https://accounts.io-market.com/#/auth/socialLogin",
  grant_type:"authorization_code",
  client_secret:"v90nhsmJVKvpEIuJ",


  // Google
  gClient_id:'997002379881-ftqglphlcvah5e3ui1lv9hgni119g05c.apps.googleusercontent.com',
  cookiepolicy: 'single_host_origin',
  gScope:'profile email',
  stripePK:"pk_test_51JPR33HCmdVWPntTtDn8z71SB3EJOhgvYMM33olpRJi1qtNW8T79BfFT63UT0C9esWVkANaEaLWB8ca15inthRN800MTvxgTol",
  // LOGOUT_URL :"https://accounts.io-market.com/#/auth/logout/",
  LOGOUT_URL: {'io-market.com':'https://accounts.io-market.com/#/auth/logout',
    'epost.gate2b.com':'https://accounts.epost.gate2b.com/#/auth/logout/',
  'xatena.io-market.com':'https://accounts.xatena.io-market.com/#/auth/logout/'},
  domain:'.io-market.com',
  languageCookie:'languageCookie',
  styleCookie:'styleCookie',
  // ACCOUNTS_URL:'https://accounts.io-market.com/#/',
  WC_APPS:"https://wc.io-market.com/subscribedApps/main.js",
  WC_PROFILE:"https://wc.io-market.com/profile/main.js",
  USER_ACTIVE_CHECK:"isUserActive",
  userInfo:"userInfo",
  BEXIO_URL: "https://bexio.io-market.com/#/",
  MTC_URL: "https://mtc.io-market.com/#/",
  mtc_cookie: "mtc_cookie",
  permissions: 'permissions',
  color:"",
  tax:"0.081",
  EBILL_ERROR_PRICE:"0.20",
  // GATE2B_URL:"https://gate2b.io-market.com/#/",
  GATE2B_URL:{'io-market.com':'https://gate2b.io-market.com/#/',
    'epost.gate2b.com':'https://edi.epost.gate2b.com/#/'},
  cookieConsent: 'cookieConsent',

  API_END_POINT_ACCOUNT : {'xatena.io-market.com':'https://fjy29.api.xatena.io-market.com/api/v1/',
  'io-market.com':'https://fjy29.api.io-market.com/api/v1/',
  'epost.gate2b.com':'https://fjy29.api.epost.gate2b.com/api/v1/'},
  STYLES : {'xatena.io-market.com':[{'--theme-color-1':'#e0b310'},{'--header-color':'#e0b31066'}],
  'io-market.com':[{'--theme-color-1':'#8B9635'},{'--header-color':'#474d226e'}],
  'epost.gate2b.com':[{'--theme-color-1':'#ffcc00'},{'--header-color':'transparent'}]},
  LOGO_ORIGINAL : {'xatena.io-market.com':'Xatena.png',
  'io-market.com':'logo.png',
  'epost.gate2b.com':'e-plus-p-logo.png'},
  LOGO : {"dark":{'io-market.com': "logo.png",'xatena.io-market.com': "Xatena_dark.png","epost.gate2b.com": "e_plus_logo_final.png"},
      "light":{ 'io-market.com': "logoWhite.png",'xatena.io-market.com': "Xatena_white.png","epost.gate2b.com": "e_plus_logo_final.png"}},
  FAVI : {'xatena.io-market.com':[{"icon":'xatena.ico',"title":"accounts.whitelabel.io-market.com"}],
  'io-market.com':[{"icon":'favicon_1.ico',"title":"accounts.io-procurement.com"}],
  'epost.gate2b.com':[{"icon":'ePost Logo Icon.svg',"title":"accounts.epost.gate2b.com"}]},
  FOOTER_TEXT : {'xatena.io-market.com':'Xatena',
  'io-market.com':'iomarket',
  'epost.gate2b.com':'iomarket'},
  API_END_POINT_ADMIN: {'xatena.io-market.com':'https://fkbwl.api.xatena.io-market.com/api/v1/',
  'io-market.com':'https://fkbwl.api.io-market.com/api/v1/',
  'epost.gate2b.com':'https://fkbwl.api.epost.gate2b.com/api/v1/'},
  ACCOUNTS_URL: {'xatena.io-market.com':'https://accounts.xatena.io-market.com/#/',
  'io-market.com':'https://accounts.io-market.com/#/',
  'epost.gate2b.com':'https://accounts.epost.gate2b.com/#/'},
  PROFILE_URL:"https://accounts.",
  HELP_DESK_CONTACT:  {
    "io-market.com": 'helpdesk@io-market.com',
    "epost.gate2b.com": 'pascal.leutenegger@epostservice.ch',
    "xatena.io-market.com": 'helpdesk@io-market.com'
  },
  SALES_TEAM_CONTACT:{
    "io-market.com": 'sales@io-market.com',
    "epost.gate2b.com": 'cristina.goncalves@epostservice.ch',
    "xatena.io-market.com": 'sales@io-market.com'
  },
  COMPANY_NAME: {
    'io-market.com':'iomarket AG',
    'epost.gate2b.com':'ePost Service AG'
  }
};
